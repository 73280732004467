import React, { ReactElement, useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { Button, Grid, Wrapper } from '@farewill/ui'
import { BREAKPOINT, FONT, GTR } from '@farewill/ui/tokens'

import PATHS from 'paths'
import { Form } from 'components/Form'
import RadioButtonGroup from 'components/Form/RadioButtonGroup'
import BreakpointSwitch from 'components/BreakpointSwitch'

import { GUIDE_TYPE } from '../../constants'

const StyledRadioGroup = styled(RadioButtonGroup)`
  & > div > div + div {
    margin-top: ${GTR.M};
    max-width: 280px;
  }

  label {
    font-weight: ${FONT.WEIGHT.BOLD};
  }
`

const initialFormValues = {
  [GUIDE_TYPE]: '',
}

const SliceForm = (): ReactElement => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (values: { [GUIDE_TYPE]: string }) => {
    if (isSubmitting) return

    setIsSubmitting(true)

    navigate(PATHS.CREMATION.FUNERAL_PLAN_GET_A_GUIDE_FORM, {
      state: {
        guideType: values.GUIDE_TYPE,
      },
    })
  }

  return (
    <Wrapper>
      <Form initialValues={initialFormValues} onSubmit={handleSubmit}>
        <Grid>
          <Grid.Item>
            <StyledRadioGroup
              name={GUIDE_TYPE}
              size="L"
              options={[
                {
                  label: 'Email me a digital copy',
                  hint: 'We’ll send it straight away',
                  value: 'email',
                },
                {
                  label: 'Post me a printed copy',
                  hint: 'If you have an email address, we’ll email you too',
                  value: 'post',
                },
              ]}
              required
            />
          </Grid.Item>
          <Grid.Item>
            <BreakpointSwitch
              breakAt={BREAKPOINT.S}
              aboveComponent={
                <Button.Primary loading={isSubmitting} type="submit" wide>
                  Order my guide
                </Button.Primary>
              }
              belowComponent={
                <Button.Primary loading={isSubmitting} type="submit" stretch>
                  Order my guide
                </Button.Primary>
              }
            />
          </Grid.Item>
        </Grid>
      </Form>
    </Wrapper>
  )
}

export default SliceForm
