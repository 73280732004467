import React, { useEffect, useState } from 'react'
import { RadioGroup } from '@farewill/ui'
import { RadioGroupProps } from '@farewill/ui/components/Form/RadioGroup/types'

import { useFormContext } from '../index'

const RadioButtonGroup = ({
  size,
  name,
  legend,
  required,
  options,
  className,
}: RadioGroupProps) => {
  const { errors, touched, setTouched, handleChange, setFieldError, values } =
    useFormContext()
  const currentValue = values[name]
  const error = errors[name]
  const isTouched = touched[name]
  const [optionsWithError, setOptionsWithError] = useState(options)

  const getError = (value: string) => {
    if (!required || value) {
      setOptionsWithError(options)
      return undefined
    }

    const updatedArray = optionsWithError.map((option, index) => {
      if (index === optionsWithError.length - 1) {
        return { ...option, error: 'This field is required.' }
      }

      return option
    })

    setOptionsWithError(updatedArray)

    return true
  }

  useEffect(() => {
    setFieldError(name, getError(currentValue))
  }, [currentValue])

  return (
    <RadioGroup
      onBlur={() => setTouched({ ...touched, [name]: true })}
      name={name}
      options={isTouched && error ? optionsWithError : options}
      size={size}
      onChange={handleChange}
      value={currentValue}
      legend={legend}
      required={required}
      className={className}
    />
  )
}

export default RadioButtonGroup
