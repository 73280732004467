import React, { ReactElement } from 'react'
import { H } from '@farewill/ui'

import { CREMATION_CHEAPER_THAN_AVERAGE_UK_FUNERAL_PRICE } from 'config'
import { formatPriceInPounds } from 'lib/formatting/pricing'
import GetAGuideLayout from 'views/cremation/components/GetAGuideLayout'

import SliceForm from './slices/SliceForm'

const pageTitle =
  'Funeral Services - Arrange a meaningful funeral with Farewill'
const pageDescription = `You can arrange a meaningful funeral today or we’ll help you plan ahead with a pre-paid funeral plan from Farewill. You can save ${formatPriceInPounds(
  CREMATION_CHEAPER_THAN_AVERAGE_UK_FUNERAL_PRICE
)} compared to the average funeral in the UK.`

const GetAGuide = (): ReactElement => (
  <GetAGuideLayout
    pageTitle={pageTitle}
    pageDescription={pageDescription}
    guideType="email"
  >
    <H tag="h1" size="M" maxWidth={340}>
      How would you like to receive your guide?
    </H>
    <SliceForm />
  </GetAGuideLayout>
)

export default GetAGuide
