import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from 'react'
import PropTypes from 'prop-types'
import { ERROR_MESSAGES } from './constants'

const Context = createContext()

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERRORS':
      return { ...state, errors: action.payload }
    case 'SET_FIELD_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.name]: action.payload.value,
        },
      }
    case 'SET_IS_SUBMITTING':
      return { ...state, isSubmitting: action.payload }
    case 'SET_TOUCHED':
      return { ...state, touched: action.payload }
    case 'SET_VALUES':
      return { ...state, values: action.payload }
    default:
      return state
  }
}

const Form = ({ children, initialValues, onSubmit }) => {
  const initialState = { errors: {}, touched: {}, values: initialValues }
  const [state, dispatch] = useReducer(reducer, initialState)

  const setErrors = useCallback(
    (payload) => {
      dispatch({ type: 'SET_ERRORS', payload })
    },
    [dispatch]
  )

  const setKnownErrors = useCallback(
    (payload) => {
      Object.keys(payload).forEach((error) => {
        dispatch({
          type: 'SET_FIELD_ERROR',
          payload: { name: error, value: ERROR_MESSAGES[error] },
        })
      })
    },
    [dispatch]
  )

  const setFieldError = useCallback(
    (name, value) => {
      dispatch({ type: 'SET_FIELD_ERROR', payload: { name, value } })
    },
    [dispatch]
  )

  const setIsSubmitting = useCallback(
    (payload) => {
      dispatch({ type: 'SET_IS_SUBMITTING', payload })
    },
    [dispatch]
  )

  const setTouched = useCallback(
    (payload) => {
      dispatch({ type: 'SET_TOUCHED', payload })
    },
    [dispatch]
  )

  const setValues = useCallback(
    (payload) => {
      dispatch({ type: 'SET_VALUES', payload })
    },
    [dispatch]
  )

  const handleChange = (event) => {
    event.persist()

    const { target } = event
    const { name, value, checked, type } = target
    const newValue = type === 'checkbox' ? checked : value

    setValues({ ...state.values, [name]: newValue })
  }

  const setAllTouched = () => {
    const touched = Object.keys(initialValues).reduce((acc, key) => {
      acc[key] = true
      return acc
    }, {})
    return setTouched(touched)
  }

  const handleSubmit = async (event) => {
    if (event) event.preventDefault()

    const hasErrors = Object.values(state.errors).some((error) => error)
    if (hasErrors) return setAllTouched()

    setIsSubmitting(true)

    try {
      const result = await onSubmit(state.values)
      return result
    } finally {
      setIsSubmitting(false)
    }
  }

  const value = {
    ...state,
    handleChange,
    handleSubmit,
    setErrors,
    setKnownErrors,
    setFieldError,
    setTouched,
    setValues,
  }

  return (
    <Context.Provider value={value}>
      <form onSubmit={handleSubmit}>{children}</form>
    </Context.Provider>
  )
}

const useFormContext = () => useContext(Context)

Form.propTypes = {
  children: PropTypes.node.isRequired,
  initialValues: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onSubmit: PropTypes.func.isRequired,
}

export { Form, useFormContext }
